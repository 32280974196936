import { defineStore } from 'pinia';

import { formatYMDDash, removeSecondsFromTime } from '@/helpers/dateHelpers';

import type {
  ReservationSummary,
  ReservationsKindSummary,
} from '@/models/ReservationInterface';

import type { Activity } from '@/models/ActivityInterface';
import type { PartnerLocation } from '@/models/ProfileInterface';
import type { StatusModalOptions } from '@/components/Common/StatusModal/StatusModal.vue';
import type { CheckCode } from '@/models/CodeInterface';
import ScheduleApiService from '@/api/schedule';
import ReservationConfigurationApiService from '@/api/reservationConfiguration';

const CALENDAR_EVENT_CLASSES = {
  Bigbox: 'bigbox-event',
  Maleva: 'maleva-event',
} as const;

export const useReservationScheduleStore = defineStore('reservationSchedule', {
  state: () => ({
    editStatusOptions: {
      title: 'Reserva creada con éxito',
      message: 'Se ha modificado la reserva correctamente.',
      status: true,
      show: false,
      loading: false,
      redirect: true,
      button: 'Ver en agenda',
    },
    editSuccessful: false,
    selectedDate: formatYMDDash(new Date()),
    selectedMonth: formatYMDDash(new Date()),
    currentBoxExpiration: '' as string | Date,
    currentBoxName: '',
    creationStatusModal: null as StatusModalOptions | null,
    reservationsLoading: false,
    filteredReservations: [] as ReservationSummary[],
    bigbox_reservations: [],
    partner_client: {},
    currentReservationActivity: [] as Activity[],
    currentReservationActivityLocations: [] as PartnerLocation[],

    monthlyReservationsSummary: [] as ReservationsKindSummary[],
    dailyReservationsSummary: [] as ReservationSummary[],
    monthyReservationsLoading: false,
  }),
  actions: {
    async getMonthlyReservationsQuantity(date: string) {
      this.monthyReservationsLoading = true;

      const response = await ScheduleApiService.getMonthlyReservationsSummary({
        date,
      });

      this.monthlyReservationsSummary =
        response.data.monthly_reservation_summary;
      this.monthyReservationsLoading = false;
    },

    async getDailyReservationsSummary(date: string) {
      this.reservationsLoading = true;

      const dailyReservations =
        await ScheduleApiService.getDailyReservationsSummary({
          date,
        });

      const reservationsList =
        dailyReservations.data.daily_reservation_summary.reservations_summary.map(
          item => {
            return {
              id: item.id,
              user:
                item.client &&
                `${item.client.first_name} ${item.client.last_name}`,
              first_name: item.client && item.client.first_name,
              last_name: item.client && item.client.last_name,
              time: removeSecondsFromTime(item.reservation_hour),
              end_time: item.reservation_hour_end,
              type: item.reservation_kind,
              is_validated: item.is_validated,
              is_liquidated: item.is_liquidated,
              comments: item.comments,
              participants: item.participants,
              date: formatYMDDash(item.reservation_date),
              email: item.client && item.client.email,
              phoneNumber: item.client && item.client.phone,
              boxCode: item.code,
              expirationCode: item.expiration_date,
              activityDescription: item.activity.description,
              location: item.location || '',
              activityName: item.activity.name,
              boxName: item.activity.public_box_name || '',
              benefits: item.activity.benefits,
              benefits_title: item.activity.benefits_title,
              kind_activity: item.reservation_kind,
              internal_name: item.activity.internal_name,
              availableDays: item.available_days,
              status: item.status,
              externalMethodName: item?.external_reservation?.method_name || '',
            };
          },
        );

      this.updateDayReservationsQuantities(
        date,
        dailyReservations.data.daily_reservation_summary
          .reservations_kind_summmary,
      );

      this.dailyReservationsSummary = reservationsList;
      // Resets the date's filters
      this.filteredReservations = this.dailyReservationsSummary;
      this.reservationsLoading = false;
    },

    /**
     * Updates the specific date's summary in the calendar
     */
    updateDayReservationsQuantities(
      date: string,
      summary: ReservationsKindSummary[],
    ) {
      const formattedSummary = summary.map(summ => ({ ...summ, date }));

      const filteredDatesSummary = this.monthlyReservationsSummary.filter(
        res => date !== res.date,
      );

      this.monthlyReservationsSummary = [
        ...filteredDatesSummary,
        ...formattedSummary,
      ];
    },

    setFilteredReservations(filtered: ReservationSummary[]) {
      this.filteredReservations = filtered;
    },

    async deleteReservation(payload: { reservation: string }) {
      const response = await ScheduleApiService.deleteReservation(payload);

      const ok = response.data?.partners_delete_reservation.ok;
      if (ok) {
        await this.getMonthlyReservationsQuantity(
          formatYMDDash(this.selectedMonth),
        );
      } else {
        // pass
      }
    },
    async checkActivityLocations(activity: string) {
      const response =
        await ReservationConfigurationApiService.checkActivityLocations({
          activity,
        });
      const locations = response.data.panel_check_activity_locations;
      if (locations.length > 0) {
        this.currentReservationActivityLocations = locations;
      }
    },
    setCodeInformation(codeInformation: CheckCode) {
      const { activities, user } = codeInformation;
      const partnerClient = Object.assign({}, user);
      delete partnerClient['__typename'];
      const { expiration_date } = codeInformation.code;
      const productNameByCode = codeInformation.code.product!.name;
      const productNameByActivity = activities[0].public_box_name;
      const currentBoxName =
        activities.length > 1 ? '' : productNameByActivity || productNameByCode;
      this.currentReservationActivity = activities;
      this.partner_client = partnerClient;
      this.currentBoxExpiration = expiration_date!;
      this.currentBoxName = currentBoxName;
    },
  },
  getters: {
    getMonthlyEvents: state => {
      const events: CalendarEvents[] = state.monthlyReservationsSummary.map(
        reservation => {
          return {
            date: reservation.date!,
            title: `${reservation.reservations_quantity}`,
            className:
              CALENDAR_EVENT_CLASSES[
                reservation.reservation_kind as ReservationKindType
              ],
          };
        },
      );
      return events;
    },
  },
});

export interface CalendarEvents {
  date: string;
  title: string;
  className: string;
}

type ReservationKindType = keyof typeof CALENDAR_EVENT_CLASSES;
