// Queries
import BIGBOX_RESERVATIONS from '@/assets/graphql/queries/schedule/BigboxReservations.graphql';
import DAILY_RESERVATIONS_SUMMARY from '@/assets/graphql/queries/schedule/DailyReservationsSummary.graphql';
import MONTHLY_RESERVATIONS_SUMMARY from '@/assets/graphql/queries/schedule/MonthlyReservationsSummary.graphql';
import CHECK_CODE from '@/assets/graphql/queries/schedule/CheckCode.graphql';
//  Mutations
import CREATE_RESERVATION_MUTATION from '@/assets/graphql/mutations/schedule/CreateReservationMutation.graphql';
import DELETE_RESERVATION_MUTATION from '@/assets/graphql/mutations/schedule/DeleteReservationMutation.graphql';
import EDIT_RESERVATION_MUTATION from '@/assets/graphql/mutations/schedule/EditReservationMutation.graphql';

// service
import apolloService from '@/services/apolloService';
import * as Types from './types';

export default class {
  // QUERIES
  static getBigboxReservations() {
    return apolloService.query(BIGBOX_RESERVATIONS, undefined, {
      fetchPolicy: 'network-only',
    });
  }

  static checkCode(variables: { code: string }) {
    return apolloService.query<Types.CheckCodeResponse>(CHECK_CODE, variables, {
      fetchPolicy: 'network-only',
    });
  }

  static getDailyReservationsSummary(variables: { date: string }) {
    return apolloService.query<Types.DailyReservationSummaryResponse>(
      DAILY_RESERVATIONS_SUMMARY,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  static getMonthlyReservationsSummary(variables: { date: string }) {
    return apolloService.query<Types.MonthlyReservationsSummaryResponse>(
      MONTHLY_RESERVATIONS_SUMMARY,
      variables,
      {
        fetchPolicy: 'network-only',
      },
    );
  }

  // MUTATIONS
  static createReservation(variables: any) {
    return apolloService.mutate(CREATE_RESERVATION_MUTATION, variables);
  }

  static deleteReservation(variables: { reservation: string }) {
    return apolloService.mutate<Types.DeleteReservationResponse>(
      DELETE_RESERVATION_MUTATION,
      variables,
    );
  }

  static editReservation(variables: Types.EditReservationPayload) {
    return apolloService.mutate<Types.EditReservationResponse>(
      EDIT_RESERVATION_MUTATION,
      variables,
    );
  }
}
